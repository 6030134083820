import { logo, geometry, logo2, MathJaxIMG } from "./images";
import { activity, bell, dashboard, lessons, live, messages, profile, close, eye, no_eye, search, burger, folder, sort, book } from "./icons";
import { makePostAPICall, setToStorage, getFromStorage, getGame, getLeaderBoard, getGameInfo } from "./functions";
import { ReactComponent as Pdf } from "./icons/PDF.svg";
import { ReactComponent as Presentation } from "./icons/presentation.svg";
import { ReactComponent as Sheet } from "./icons/sheet.svg";
import sanitizeHtml from "sanitize-html";
import { ReactComponent as Word } from "./icons/word.svg";

const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

const returnSubmissionType = (string) => {
	if (string === "word_document") return ".doc,.docx";
	if (string === "excel_document") return ".xlxs,";
	if (string === "powerpoint_document") return ".ppt,.pptx";
	if (string === "image") {
		return ".jpg,.jpeg";
	} else {
		return "";
	}
};

const filterObject = (obj, filter, filterValue) =>
	Object.keys(obj).reduce(
		(acc, val) =>
			obj[val][filter] === filterValue
				? acc
				: {
						...acc,
						[val]: obj[val],
				  },
		{}
	);

const customStyles = {
	rows: {
		style: {
			minHeight: "60px",
		},
	},
	headCells: {
		style: {
			paddingLeft: "15px",
			paddingRight: "8px",
			color: "white",
			backgroundColor: "var(--primary-color)",
		},
	},
	table: {
		style: {
			backgroundColor: "#F5F6F8",
		},
	},
};

function getFileExtension(filename) {
	var ext = /^.+\.([^.]+)$/.exec(filename);
	return ext == null ? "" : ext[1];
}

const getFileName = (url) => url.substring(url.lastIndexOf("/") + 1);

const returnAttachmentImage = (url) => {
	const fileType = getFileExtension(url);
	if (fileType === "pdf") return <Pdf />;
	else if (fileType === "doc" || fileType === "docx" || fileType === "txt") return <Word />;
	else if (fileType === "xlsx" || fileType === "xlsm") return <Sheet />;
	else if (fileType === "ppt" || fileType === "pptx") return <Presentation />;
};

const sanitizeHTMLOptions = {
	allowedTags: sanitizeHtml.defaults.allowedTags.concat(["ul", "ol", "img", "ins"]),
	allowedAttributes: {
		a: ["name", "target"],
		img: ["src"],
	},
	disallowedTagsMode: "discard",
};

const removeItemsFromStorage = (items) => items.forEach((item) => localStorage.removeItem(item));

const fileHref = process.env.NODE_ENV === "development" ? process.env.REACT_APP_LOCAL_ROOTURL : process.env.REACT_APP_PROD_ROOTURL;

export {
	logo,
	geometry,
	logo2,
	MathJaxIMG,
	activity,
	bell,
	dashboard,
	lessons,
	live,
	messages,
	profile,
	close,
	eye,
	no_eye,
	search,
	burger,
	folder,
	sort,
	book,
	capitalizeFirstLetter,
	returnSubmissionType,
	filterObject,
	makePostAPICall,
	setToStorage,
	getFromStorage,
	customStyles,
	getFileExtension,
	getFileName,
	returnAttachmentImage,
	fileHref,
	getGame,
	getLeaderBoard,
	getGameInfo,
	sanitizeHTMLOptions,
	removeItemsFromStorage,
};
